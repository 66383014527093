// import "./styles/styles.scss";
import brush1 from "./assets/brushs/mask1.png"
import brush2 from "./assets/brushs/mask2.png"
import brush3 from "./assets/brushs/mask3.png"
import brush4 from "./assets/brushs/mask4.png"
import brush5 from "./assets/brushs/mask5.png"

const FETCH_URL = process.env.API_URL;

var isFullScreen = false;
var creditsAreVisible = false;
var lang = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();
const defaultFloorID = 1;
const defaultRoomID = 1;
const defaultPovID = 1;
const spinner = document.querySelector(".loader");
window.app = {};

async function getData(floor = "", room = "", pov = "") { 
  let floorID = floor.toString();
  let roomID = room.toString();
  let povID = pov.toString();
  //ex url: http://localhost:3000/api/get_images?floor=1&room=1&pov=1
  //${FETCH_URL}/get_images?floor=${floorID}&room=${roomID}&pov=${povID}`
  const response = await fetch(
    `${FETCH_URL}/get_images?floor=${floorID}&room=${roomID}&pov=${povID}`
  ); 
  const data = response.json();

  return data;
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );

  return vars;
}

function init() {
  //default first render
  const GetParameters = getUrlVars();

  if (!Object.keys(GetParameters).length) {
    //default routing / no room id in url 
    getData().then((data) => {
      createApp(data);
    });
    return;
  }

  if (GetParameters.floor && GetParameters.room && GetParameters.pov) { //case: all location infos are specified
    let floorID = GetParameters.floor;
    let roomID = GetParameters.room;
    let povID = GetParameters.pov;
    floorID = floorID.toString();
    roomID = roomID.toString();
    povID = povID.toString();

    getData(floorID, roomID, povID).then((data) => {
      createApp(data);
    });
    return;
  }

  if (GetParameters.floor && GetParameters.room && !GetParameters.pov) {
    //case: floor & room are specified
    //http://127.0.0.1:8080/?floor=1&room=1
    let floorID = GetParameters.floor;
    let roomID = GetParameters.room;
    floorID = floorID.toString();
    roomID = roomID.toString();

    getData(floorID, roomID).then((data) => {
      createApp(data);
    });
    return;
  }
  
  if (GetParameters.floor && !GetParameters.room && !GetParameters.pov) {
    //case: only floor is specified
    let floorID = GetParameters.floor;
    floorID = floorID.toString();

    getData(floorID).then((data) => {
      createApp(data);
    });
    return;
  } 
    
}

init();

function createApp(data) {
  // console.log("data", data);
  if (!data) {
    console.log('err: no data')
    return;
  }

  const app = new PIXI.Application({
    resizeTo: window,
    resolution: devicePixelRatio,
  });

  document.querySelector(".canvas-container").appendChild(app.view);
  spinner.classList.remove("active");

  const { stage } = app;

  stage.sortableChildren = true;

//todo: true urls
  
  const ratioViewport = (window.innerWidth / window.innerHeight);

  const imgOptimBaseUrl = "https://aghhekazen.cloudimg.io/v7/";
  const baseUrl = "image-nuage.com/";

  let imgOptim = "?w=2000&force_format=jpeg&optipress=1&q=80&aspect_ratio="+ratioViewport.toFixed(1)+"&gravity=center";

  if (app.screen.width < 812) {
    imgOptim = "?w=800&force_format=jpeg&optipress=1&q=80&aspect_ratio="+ratioViewport.toFixed(1)+"&gravity=center";
  }

  //todo: redefine images directory 
  const backgroundsDirectory =
    imgOptimBaseUrl + baseUrl + "images/backgrounds/";
  const brushsDirectory = "assets/brushs/";

  const brushes = [brush1, brush2, brush3, brush4, brush5];
  var brush;
  generateBrush();

  var allPictures = data.images;
  var captions = [];
  var picturesCounter = 0;
  var allPictureAdded = false;
  var preloadedPictures = preloadPictures();
  var resizeTimer;
  var refreshImage;
  var spriteFromLastRender;
  var lastRenderedObject;
  var currentLocation = data.locationID;
  var currentMaxPov = data.maxPov; //string
  var blinkTxt = document.querySelectorAll(".screenshot .blink");
  window.app.currentLocation = currentLocation;
  
    var allLocationsNamesFR = {
      floors: {
        floor_0: {
          name: "r.d.c.",
          rooms: [
            { id: "room_0", name: "veranda", index: "0" },
            { id: "room_1", name: "grande salle", index: "1" },
            { id: "room_2", name: "petite salle", index: "2" },
          ],
        },
        floor_1: {
          name: "étage",
          rooms: [
            { id: "room_0", name: "espace traversant", index: "0" },
            { id: "room_1", name: "grande salle", index: "1" },
            { id: "room_2", name: "petite salle", index: "2" },
          ],
        },
      },
    };

  
    
    var allLocationsNamesEN = {
      floors: {
        floor_0: {
          name: "ground floor",
          rooms: [
            { id: "room_0", name: "veranda", index: "0" },
            { id: "room_1", name: "large room", index: "1" },
            { id: "room_2", name: "small room", index: "2" },
          ],
        },
        floor_1: {
          name: "first floor",
          rooms: [
            { id: "room_0", name: "crossing space", index: "0" },
            { id: "room_1", name: "large room", index: "1" },
            { id: "room_2", name: "small room", index: "2" },
          ],
        },
      },
    };

  if (lang == "fr") {
    var allLocationsNames = allLocationsNamesFR;
  } else {
    var allLocationsNames = allLocationsNamesEN;
  }

  window.app.allLocationsNamesFR = allLocationsNamesFR;
  window.app.allLocationsNamesEN = allLocationsNamesEN;

  // console.log(allLocationsNames);

  function convertLocationsLabels(toConvert = {type: "", value: "", level: ""}) { // type=floor||room
    if (toConvert.type === "floor") {
      const key = toConvert.value;
      const value = allLocationsNames.floors?.[key].name;
      return value.toString().toLowerCase();
    } else {
      const roomId = toConvert.value;
      const floorId = toConvert.level;
      const roomsAtCurrentFloor = allLocationsNames.floors?.[floorId]?.rooms;
      const findValue = roomsAtCurrentFloor.find((room) => room.id === roomId);
      const value = findValue.name;
      return value.toString().toLowerCase();
    }
  }

  var convertedCurrentLocation = {
    //todo: convert room
    floor: convertLocationsLabels({
      type: "floor",
      value: currentLocation.floorIndex,
    }),
    room: convertLocationsLabels({
      type: "room",
      value: currentLocation.roomIndex,
      level: currentLocation.floorIndex
    }),
  };

  var convertedFloorsLabels = [
    convertLocationsLabels({
      type: "floor",
      value: "floor_0",
    }), convertLocationsLabels({
      type: "floor",
      value: "floor_1",
    })
  ];

  var convertedRoomsLabels;

  if (currentLocation.floorIndex === "floor_0") {
     convertedRoomsLabels = [
       convertLocationsLabels({
         type: "room",
         value: "room_0",
         level: "floor_0",
       }),
       convertLocationsLabels({
         type: "room",
         value: "room_1",
         level: "floor_0",
       }),
       convertLocationsLabels({
         type: "room",
         value: "room_2",
         level: "floor_0",
       }),
     ];
  } else {
    convertedRoomsLabels = [
      convertLocationsLabels({
        type: "room",
        value: "room_0",
        level: "floor_1",
      }),
      convertLocationsLabels({
        type: "room",
        value: "room_1",
        level: "floor_1",
      }),
      convertLocationsLabels({
        type: "room",
        value: "room_2",
        level: "floor_1",
      }),
    ];
  }

  function getIndexForCurrentRoom(floor, roomLabel) {
    if(!floor) {
      console.log('err: no floor provided');
    }
    if(!roomLabel) {
      console.log('err: no roomLabel provided');
    }

    return allLocationsNames.floors[floor].rooms.find(
      (room) => room.name === roomLabel
    ).index;
  }
    
  function updateMenuLabels() {
    
    window.app.convertedCurrentLocation = convertedCurrentLocation;
    window.app.convertedFloorsLabels = convertedCurrentLocation;
    window.app.convertedRoomsLabels = convertedRoomsLabels;

    const floorsLabels = document.querySelectorAll(".label-floor");
    const roomsLabels = document.querySelectorAll(".label-room");
    const convertedCurrentFloor = convertedCurrentLocation.floor;
    const convertedCurrentRoom = convertedCurrentLocation.room;

    //update for floors
    floorsLabels[0].innerText = convertedCurrentFloor;

    floorsLabels[1].innerText = convertedFloorsLabels.find(
      (label) => label !== convertedCurrentFloor
    );

    //update for rooms
    roomsLabels[0].innerText = convertedCurrentRoom;

    const notSelectedRooms = convertedRoomsLabels.filter(
      (label) => label !== convertedCurrentRoom
    );

    notSelectedRooms.forEach((room, index) => {
      ;
      const currentDomElement = roomsLabels[index + 1];
      currentDomElement.innerText = room;
      const indexOfCurrentRoom = getIndexForCurrentRoom(
        currentLocation.floorIndex,
        room
      );
      currentDomElement.setAttribute("data-index", indexOfCurrentRoom);
    })

    //update pov
    const currentPovDomElement = document.querySelector(".stepper-current");
    const maxPovDomElement = document.querySelector(".stepper-max");
    maxPovDomElement.innerText = currentMaxPov;
    const getCurrentPovIndex = currentLocation.povIndex;
    const currentPovIndex = getCurrentPovIndex.charAt(
      getCurrentPovIndex.length - 1
    );
    let currentPovNumber = parseInt(currentPovIndex);
    currentPovNumber = currentPovNumber + 1;
    currentPovDomElement.innerText = currentPovNumber;
    currentPovDomElement.setAttribute("data-pov", currentPovNumber);
  }

  updateMenuLabels();

  app.loader.load(setup);

  // console.log("initcurrentloc: currentLocation", currentLocation);
  // console.log("convertedCurrentLocation:", convertedCurrentLocation);

  function convertLocation(locationObject) {
    if (typeof locationObject !== "object") {
      console.log("err: must be an object")
    }
  }

  function generateBrush() {
    var randomNbr = Math.floor(Math.random() * brushes.length);
    brush = PIXI.Sprite.from(brushes[randomNbr]);
    brush.scale.set(0.35);
    brush.anchor.set(0.5);
    brush.alpha = 0.1;
  }

  function setup(loader, resources) {
    const renderTexture = PIXI.RenderTexture.create(
      app.screen.width,
      app.screen.height
    );

    // handleNavigation(); todo:remove ?

    const renderTextureSprite = new PIXI.Sprite(renderTexture);

    stage.addChild(renderTextureSprite);

    //default state

    const image0 = createSprite(preloadedPictures[0]);
    image0.zIndex = 0;
    stage.addChild(image0);

    const image1 = createSprite(preloadedPictures[1]);
    image1.zIndex = 3;
    stage.addChild(image1);
    picturesCounter++;

    addCaptions(picturesCounter);

    image1.mask = renderTextureSprite;

    app.stage.interactive = true;
    app.stage.on("pointerdown", pointerDown);
    app.stage.on("pointerup", pointerUp);
    app.stage.on("pointermove", pointerMove);

    let dragging = false;
    let defaultState = true;

    window.addEventListener("resize", onResizeSimple);

    function onResizeSimple(event) {
      //window.location.reload(false); 
    }

    function onResize(event) {
      spinner.classList.add("active");

      lastRenderedObject = app.renderer.plugins.extract.canvas(
        app.renderer._lastObjectRendered
      );

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        for (var i = stage.children.length - 1; i >= 0; i--) {
          stage.removeChild(stage.children[i]);
        }

        // stage.removeChild(spriteFromLastRender);
        let renderTexture = PIXI.RenderTexture.create(
          app.screen.width,
          app.screen.height
        );

        let renderTextureSprite = new PIXI.Sprite(renderTexture);

        stage.addChild(renderTextureSprite);

        var blobURL = lastRenderedObject; //stage
        var texture = PIXI.Texture.from(blobURL, {
          resolution: devicePixelRatio,
        });

        spriteFromLastRender = new PIXI.Sprite(texture);
        spriteFromLastRender.zIndex = -1;
        addImageToStage(spriteFromLastRender);

        refreshImage = createSprite(preloadedPictures[picturesCounter]);
        refreshImage.zIndex = 30;
        addImageToStage(refreshImage);

        refreshImage.mask = renderTextureSprite;
        app.stage.on("pointermove", pointerMove3);

        function pointerMove3(event) {
          if (dragging) {
            brush.position.copyFrom(event.data.global);
            app.renderer.render(brush, renderTexture, false, null, false);
          }
        }

        spinner.classList.remove("active");
      }, 250);
    }

    function pointerMove(event) {
      if (creditsAreVisible) {
        return;
      }

      if (dragging && defaultState) {
        brush.position.copyFrom(event.data.global);
        app.renderer.render(brush, renderTexture, false, null, false);
      }
    }

    function pointerMove2(event) {
      if (dragging) {
        brush.position.copyFrom(event.data.global);
        app.renderer.render(brush, renderTexture, false, null, false);
      }
    }

    function pointerDown(event) {
      if (creditsAreVisible) {
        return;
      }

      dragging = true;
      pointerMove(event);
    }

    function pointerUp(event) {
      if (creditsAreVisible) {
        return;
      }

      picturesCounter++;

      if (picturesCounter === 6) {
        blinkTxt.forEach((item) => item.classList.add("blink-active"));
      } 
      
      if (picturesCounter >= data.images.length) {
        allPictureAdded = true;
        dragging = false;
        return;
      }

      addCaptions(picturesCounter);

      dragging = false;
      defaultState = false;

      let renderTexture = PIXI.RenderTexture.create(
        app.screen.width,
        app.screen.height
      );

      let renderTextureSprite = new PIXI.Sprite(renderTexture);

      stage.addChild(renderTextureSprite);

      var blobURL = app.renderer.plugins.extract.canvas(
        app.renderer._lastObjectRendered
      ); //stage
      var texture = PIXI.Texture.from(blobURL, {
        resolution: devicePixelRatio,
      });
      stage.removeChild(spriteFromLastRender);

      spriteFromLastRender = new PIXI.Sprite(texture);
      spriteFromLastRender.zIndex = -1;
      addImageToStage(spriteFromLastRender);

      refreshImage = createSprite(preloadedPictures[picturesCounter]);
      refreshImage.zIndex = 30;
      addImageToStage(refreshImage);

      refreshImage.mask = renderTextureSprite;

      app.stage.on("pointermove", pointerMove2);

      function pointerMove2(event) {
        if (dragging) {
          brush.position.copyFrom(event.data.global);
          app.renderer.render(brush, renderTexture, false, null, false);
        }
      }

      generateBrush();
    }

    //screenshot
    const button = document.querySelector(".screenshot-valid");

    // const renderTexture = PIXI.RenderTexture.create({ width: 200, height: 200 }); // enter your size , maybe from app.screen

    button.addEventListener("mousedown", (e) => {
      e.preventDefault();
      spinner.classList.add("active");
      // console.log(1111);


      app.renderer.plugins.extract
        .canvas(app.renderer._lastObjectRendered) //stage
        .toBlob(function (b) {

        //Send to IPFS
        const formData = new FormData();
        const nftCredit = document.querySelector(".credits__content").textContent;

        formData.append("content", b, "image/png");
        formData.append("fullcredit", nftCredit);
        formData.append("location", currentLocation.floorIndex +'/'+ currentLocation.roomIndex +'/'+currentLocation.povIndex);
        
        fetch("https://nft-storage-node-k2zmp.ondigitalocean.app/upload", { method: 'POST', body: formData })
        .then(function(content) {   
         return content.json();
        }).then(function(data) {
          const screen_nft_content =
            lang === "en"
              ? "<li><a href='" +
                data.filepath +
                "' rel='noopener' target='_blank'>View image</a></li><li><a href='" +
                data.image +
                "' rel='noopener' target='_blank'>See the image stored on IPFS</a></li>"
              : "<li><a href='" +
                data.filepath +
                "' rel='noopener' target='_blank'>Voir l'image</a></li><li><a href='" +
                data.image +
                "' rel='noopener' target='_blank'>Voir l'image stockée sur IPFS</a></li>";
          
            document.querySelector(".screenshot__content_nft").innerHTML =
              screen_nft_content;
           console.log(data);
          spinner.classList.remove("active");
        });

          var newImg = document.createElement("img"),
            url = URL.createObjectURL(b);

          const a = document.createElement("a");
          a.href = url;
          a.download = "VillaDuParc_Image-Nuage_"+currentLocation.floorIndex+"_"+currentLocation.roomIndex+"_"+currentLocation.povIndex;

          const clickHandler = () => {
            setTimeout(() => {
              
              URL.revokeObjectURL(url);
              this.removeEventListener("click", clickHandler);
            }, 150);
          };

          a.addEventListener("click", clickHandler, false);

          a.click();
        }, "image/png");
    });
  }

  function addCaptions(limit = 1) {
    const captionsToDisplay = captions.slice(0, limit);
    const creditsList = document.querySelector(".credits__content");
    creditsList.innerHTML = "";

    captionsToDisplay.forEach((caption, index) => {
      let li = document.createElement("li");
      li.classList.add("credits__item");
      li.innerHTML = `${index + 1}. ${caption} `;
      creditsList.appendChild(li);
    });
  }

  function preloadPictures(limit = [0, data.images.length], reset = false) {
    let indexes = [];
    let picturesSlice = allPictures.slice(limit[0], limit[1]);

    if (reset == true) {
      picturesCounter = 0;
      allPictureAdded = true;
      
      return preloadedPictures;
    }

    picturesSlice.forEach((picture, index) => {
      const name = "t" + (index + picturesCounter);
      indexes.push(name);
      captions.push(picture.caption);
      app.loader.add(name, backgroundsDirectory + picture.src + imgOptim);
    });

    return indexes;
  }

  function createSprite(name) {
    const sprite = new PIXI.Sprite(app.loader.resources[name].texture);

    sprite.position.set(app.screen.width / 2, app.screen.height / 2);
    sprite.width = app.screen.width;
    sprite.height = app.screen.height;
    sprite.anchor.set(0.5);
    return sprite;
  }

  function addImageToStage(image) {
    image.position.set(app.screen.width / 2, app.screen.height / 2);
    image.width = app.screen.width;
    image.height = app.screen.height;
    image.anchor.set(0.5);

    stage.addChild(image);
  }

  function routeToAny(){
    const triggers = document.querySelectorAll(".route-to-any");
    triggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        window.location = window.location.href.split("?")[0]; //refresh without get paramaters
      });
    });
  }

  function routeToFloor() {
    const trigger = document.querySelector(".route-to-change-floor");

    trigger.addEventListener("click", (e) => {
      if(currentLocation.floorIndex === "floor_0") { 
        //go upstairs
        const queryParams = "?floor=1";
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      } else {
        //go down
        const queryParams = "?floor=0";
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      }
    })
  }

  function routeToRoom() { //exurl: http://127.0.0.1:8080/?floor=1&room=1
    const triggers = document.querySelectorAll(".route-to-change-room");

    triggers.forEach(trigger => {
      trigger.addEventListener("click", (e) => {
        const indexForClickedElement =
          e.target.firstChild.getAttribute("data-index");
        let currentFloor = currentLocation.floorIndex;
        currentFloor = currentFloor.charAt(currentFloor.length - 1);
        const queryParams = `?floor=${currentFloor}&room=${indexForClickedElement}`; //todo:correct room
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      });
    });

  }

  function routeToPov() {
    const decreaseTrigger = document.querySelector(".decrease-pov");
    const increaseTrigger = document.querySelector(".increase-pov");
    let currentFloor = currentLocation.floorIndex;
    const currentFloorKey = currentLocation.floorIndex;
    const currentFloorIndex = currentFloorKey.charAt(
      currentFloorKey.length - 1
    );
    const currentRoomKey = currentLocation.roomIndex;
    const currentRoomIndex = currentRoomKey.charAt(currentRoomKey.length - 1);
    // const targetPovIndex = document.querySelector(".stepper-current");
    let targetPovIndex = parseInt(
      document.querySelector(".stepper-current").getAttribute("data-pov")
    ); 
    

    // //decrease
    decreaseTrigger.addEventListener("click", (e) => {
      if(targetPovIndex === 1) {
        targetPovIndex = currentMaxPov - 1;
        const queryParams = `?floor=${currentFloorIndex}&room=${currentRoomIndex}&pov=${targetPovIndex}`;
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      } else {
        targetPovIndex = targetPovIndex - 2;
        const queryParams = `?floor=${currentFloorIndex}&room=${currentRoomIndex}&pov=${targetPovIndex}`;
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      }
    });

    // //increase
    increaseTrigger.addEventListener("click", (e) => {
      if(targetPovIndex !== currentMaxPov) {
        const queryParams = `?floor=${currentFloorIndex}&room=${currentRoomIndex}&pov=${targetPovIndex}`;
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      } else { //back to pov:0
        const queryParams = `?floor=${currentFloorIndex}&room=${currentRoomIndex}&pov=0`;
        const UrlWithoutParams = window.location.origin;
        location.assign(location.origin + location.pathname + queryParams);
      }
    });
  }

  function router(){
    routeToAny();
    routeToFloor();
    routeToRoom();
    routeToPov();
  }

  router();

}

function refresh(stage) {
  if (!stage) {
    return;
  }

  const GetParameters = getUrlVars();
  let roomID = defaultRoomID;

  if (Object.keys(GetParameters).length) {
    //no room id in url
    if (GetParameters.room) {
      roomID = GetParameters.room;
      roomID = roomID.toString();
    } else {
      throw "wrong parameter";
    }
  }

  for (var i = stage.children.length - 1; i >= 0; i--) {
    stage.removeChild(stage.children[i]);
  }

  document.querySelector(".canvas-container").innerHTML = "";

  getData(roomID).then((data) => {
    createApp(data);
  });

  

} //create app

//top nav actions
function clickShowSub() {
  const trigger = document.querySelectorAll(".click-show-sub");
  
  trigger.forEach(item => {
    item.addEventListener("click", (e) => {
      e.stopPropagation();

      if (e.target.parentNode["classList"].contains("top-nav__sub")) {
        return;
      } 
      
      const clickedTarget = e.target;

      const bufferDomElements = Array.from(clickedTarget.children);
      const subMenu = bufferDomElements.find((elements) =>
        elements["classList"].contains("top-nav__sub")
      );

      if (subMenu) {
        subMenu.classList.toggle("top-nav__sub--is-active");
      }

    })
  })
}
clickShowSub();

 function closeAllSubs() { //todo: 
    const trigger = document.querySelectorAll(".top-nav__sub");

    trigger.forEach((item) => {
      item.classList.remove("top-nav__sub--is-active");
    });

 }

function showCredits() {
  const trigger = document.querySelector(".credits");
  const widthReference = document.querySelector(".site-infos");
  const referencePosition = widthReference.getBoundingClientRect();
  const creditsContent = document.querySelector(".credits__container");
  const creditsWidth = window.innerWidth - referencePosition.left;
  const infosContent = document.querySelector(".site-infos__content");
  const screenshotContent = document.querySelector(".screenshot__container");
  creditsContent.style.width = (creditsWidth - 1) + "px";

  trigger.addEventListener("click", (e) => {
    infosContent.classList.remove("is-active");
    screenshotContent.classList.remove("is-active");

    if (
      e.target.classList.contains("close-container") ||
      e.target.classList.contains("close-ico")
    ) {
      creditsContent.classList.remove("is-active");
    } else if (
      e.target.classList.contains("top-nav__item") ||
      e.target.classList.contains("desk-only") ||
      e.target.classList.contains("mobile-only")
    ) {
      creditsContent.classList.toggle("is-active");
    } else {
      creditsContent.classList.add("is-active");
    }
  });
  
}

showCredits();

function showSiteInfos() {
  const trigger = document.querySelector(".site-infos");
  const closeTrigger = document.querySelector(".site-infos__content .close-container");
  const triggerPosition = trigger.getBoundingClientRect();
  const infosWidth = window.innerWidth - triggerPosition.left;
  const infosContent = document.querySelector(".site-infos__content");
  const creditsContent = document.querySelector(".credits__container");
  const screenshotContent = document.querySelector(".screenshot__container");
  infosContent.style.width = (infosWidth - 1 ) + "px";

  trigger.addEventListener("click", (e) => {
    creditsContent.classList.remove("is-active");
    screenshotContent.classList.remove("is-active");

    if (
      e.target.classList.contains("close-container") ||
      e.target.classList.contains("close-ico")
    ) {
      infosContent.classList.remove("is-active");
    } else if (
      e.target.classList.contains("top-nav__item") ||
      e.target.classList.contains("desk-only") ||
      e.target.classList.contains("mobile-only")
    ) {
      infosContent.classList.toggle("is-active");
    } else {
      infosContent.classList.add("is-active");
    }
  });

}

showSiteInfos();

function showScreenshot() {
  const trigger = document.querySelector(".screenshot");
  const widthReference = document.querySelector(".site-infos");
  const referencePosition = widthReference.getBoundingClientRect();
  const screenshotContent = document.querySelector(".screenshot__container");
  const screenshotWidth = window.innerWidth - referencePosition.left;
  const infosContent = document.querySelector(".site-infos__content");
  const creditsContent = document.querySelector(".credits__container");
  screenshotContent.style.width = (screenshotWidth - 1) + "px";

  trigger.addEventListener("click", (e) => {
    infosContent.classList.remove("is-active");
    creditsContent.classList.remove("is-active");

    if (
      e.target.classList.contains("close-container") ||
      e.target.classList.contains("close-ico")
    ) {
      screenshotContent.classList.remove("is-active");
    } else if (
      e.target.classList.contains("top-nav__item") ||
      e.target.classList.contains("desk-only") ||
      e.target.classList.contains("mobile-only")
    ) {
      screenshotContent.classList.toggle("is-active");
    } else {
      screenshotContent.classList.add("is-active");
    }
  });
  
}

showScreenshot();

document.querySelectorAll(".label-floor").forEach((e) => {
  e.style.pointerEvents = "none";
});

document.querySelectorAll(".label-room").forEach((e) => {
  e.style.pointerEvents = "none";
});

function phoneFullscreenRotation() {
  const phoneRotate = document.querySelector(".phone-rotation");
  const app = document.querySelector("#app");
  
  phoneRotate.addEventListener('click', function() {
    if(document.documentElement.requestFullscreen) {
      app.requestFullscreen();
    }
    else if(document.documentElement.webkitRequestFullScreen) {
      app.webkitRequestFullScreen();
    }

   //restart
    document.querySelector(".canvas-container").innerHTML='';
    init();
    app.loader.load(setup);
    

  screen.orientation.lock("landscape-primary")
    .then(function() {

    })
    .catch(function(error) {
      alert(error);
    });
  });
}

phoneFullscreenRotation();

function toggleLang() {
  const triggers = document.querySelectorAll(".toggle-lang");

  if (lang.includes("fr")) {
    lang = "fr";
  } else {
    lang = "en";
  }

  if(localStorage.getItem('lang')) {
      lang = localStorage.getItem('lang');
  } 

  triggers.forEach(item => {
    item.addEventListener("click", (e) => {
      lang = e.target.getAttribute("data-lang");
      localStorage.setItem('lang', lang);
      hydrateContent();
      hydrateMenuLabels(lang);
    })
  })
  
}

toggleLang();

async function getContent() {
  if(!lang) {
    console.log("err: no lang provided");
    return;
  }

  const response = await fetch(
    `${FETCH_URL}/get_content?lang=${lang}`
  ); 

  const data = response.json();
  return data;

}

function hydrateContent(){
  spinner.classList.add("active");
  getContent().then((data) => {
    hydrateNav(data?.nav_labels);
    hydrateSiteInfos(data?.site_infos);
    hydrateDownload(data?.download);

    spinner.classList.remove("active");
  });
  
}
hydrateContent();

function hydrateNav(data){
  const dataNav = data;

  if (!dataNav) {
    console.log("err: missing content");
    return;
  }

  const navLabels = ["site-infos-label", "credits-label", "download-label"];

  navLabels.forEach((element) => {
    document.querySelector(`.${element}`).textContent = dataNav[element];
  });
}

function hydrateSiteInfos(data) {
  const dataSiteInfos = data;

  if (!dataSiteInfos) {
    console.log("err: missing content");
    return;
  }
  
  const domElementForContent = document.querySelector(".site-infos__txt");
  domElementForContent.innerHTML = "";
  const container = document.createElement("div");

  //create paragraph
  const siteInfosReceivedTxt = dataSiteInfos["site-infos__txt"];

  siteInfosReceivedTxt.forEach(element => {
    const paragraph = document.createElement("p");
    const txt = document.createTextNode(element);
    paragraph.appendChild(txt);
    
    container.appendChild(paragraph);
  });

  //create links
  const siteInfosReceivedLinks = dataSiteInfos["links"];
  const linksContainer = document.createElement("div");

  siteInfosReceivedLinks.forEach((element) => {
    const paragraph = document.createElement("p");
    const beforeTxt = document.createTextNode(element.beforetext);
    paragraph.appendChild(beforeTxt);

    const a = document.createElement("a");
    const linkText = document.createTextNode(element.text);
    a.appendChild(linkText);
    a.href = element["href"];
    a.setAttribute('target', '_blank');

    paragraph.appendChild(a);

    container.appendChild(paragraph);
  });

  domElementForContent.appendChild(container);
}
function hydrateDownload(data) {
  const dataDownload = data;

  if (!dataDownload) {
    console.log("err: missing content");
    return;
  }

  const domElementForDlBtn = document.querySelector(".dl_image");
  domElementForDlBtn.textContent = dataDownload.dl_image;

  //txt
  const domListContainer = document.querySelector(".screenshot__content");

  const domRemove = domListContainer.querySelectorAll("li:not(#btn-grenerate)");
  domRemove.forEach((item) => {
    item.innerHTML = "";
  });
  
  dataDownload.content.forEach((item, index) => {
    const li = document.createElement("li");
    const br = document.createElement("br");
    const txt = document.createTextNode(item);
    li.append(br);
    li.append(txt);

    domListContainer.insertAdjacentElement("beforeend", li);
  })
  
}

function hydrateMenuLabels(lang) {
    const floorsLabels = document.querySelectorAll(".label-floor");
    const roomsLabels = document.querySelectorAll(".label-room");
    if (lang == "fr") {
      var allLocationsNames = window.app.allLocationsNamesFR;
    } else {
      var allLocationsNames = window.app.allLocationsNamesEN;
    }

    //update for floors
    floorsLabels[0].innerText = allLocationsNames.floors.floor_0.name;
    floorsLabels[1].innerText = allLocationsNames.floors.floor_1.name;



    //update for rooms
    roomsLabels[0].innerText = allLocationsNames.floors[window.app.currentLocation.floorIndex].rooms[0].name;
    roomsLabels[1].innerText = allLocationsNames.floors[window.app.currentLocation.floorIndex].rooms[1].name;
    roomsLabels[2].innerText = allLocationsNames.floors[window.app.currentLocation.floorIndex].rooms[2].name;



  }

function setFullScreen() {
  const app = document.querySelector("#app");
  
  if(document.documentElement.requestFullscreen) {
    app.requestFullscreen();
  }
  else if(document.documentElement.webkitRequestFullScreen) {
    app.webkitRequestFullScreen();
  }
    //restart
    document.querySelector(".canvas-container").innerHTML='';
    init();
    app.loader.load(setup);
}

function exitFullScreen() {
  document.exitFullscreen();
}

function fullScreen() {
  document.querySelector(".toggle-fullscreen").onclick = () => {
    console.log("toggle fullscreen6");
    
    if (isFullScreen) {
      exitFullScreen();
    } else {
      setFullScreen();
    }
    isFullScreen = !isFullScreen;

  };

  document.querySelector("body").onkeydown = function (e) {
    const key = e.key || e.keyCode;

    if (key == "f" || key == "70") {
      if (isFullScreen) {
        exitFullScreen();
      } else {
        setFullScreen();
      }
      isFullScreen = !isFullScreen;
    }
  };
}

fullScreen();